import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  id: "app",
  class: "app-content"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_van_cell = _resolveComponent("van-cell");

  const _component_router_view = _resolveComponent("router-view");

  return _openBlock(), _createElementBlock("div", _hoisted_1, [$data.isShow ? (_openBlock(), _createBlock(_component_van_cell, {
    key: 0,
    title: "点击下载「慈光讲堂」App",
    "is-link": "",
    to: "/sp/cgapp/190",
    icon: "down"
  })) : _createCommentVNode("", true), _createVNode(_component_router_view)]);
}